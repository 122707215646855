import React from 'react'
import './Network.css'
import { Row, Col, Card } from 'react-bootstrap'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function Network() {
    return (
        <div>
            <div className='network-div'>
                <img src='/Images/Banner/network-banner.jpg' alt='' className='service' />
                <h2>Seamless Connectivity</h2>
            </div>

            <div className='object-div'>
                <h1>Network Solutions</h1>
                <div className='description'>
                    <Row>
                        <Col xs={12} md={6} >
                            <img src='/Images/services/network.jpg' alt='' className='network-img' />
                        </Col>
                        <Col xs={12} md={6}>
                            <p className='net-p' style={{ lineHeight: '4rem' }}>
                                OceanWaves is a cutting-edge technology company specializing
                                in providing top-notch Network Solutions for businesses
                                seeking a robust and reliable online presence. With a
                                commitment to excellence, OceanWaves offers a comprehensive
                                suite of services tailored to meet the diverse needs of its
                                clients. From seamless domain registration to secure and
                                scalable website hosting, OceanWaves ensures that businesses
                                can navigate the digital landscape with confidence. The
                                company's experienced team of professionals is dedicated
                                to delivering innovative solutions that optimize network
                                performance, enhance security, and foster online growth.
                                With OceanWaves as your trusted partner, you can ride the
                                waves of the digital era with a secure and efficient network
                                infrastructure, setting the stage for success in the online domain.</p>
                        </Col>
                    </Row>
                </div>
                <div className='types-of-service'>
                    <h1>Types of services</h1>

                    <OwlCarousel
                        autoplay
                        loop
                        items={4}
                        margin={2}
                        responsive={{
                            0: {
                                items: 1 // Number of items to show on small screens
                            },
                            768: {
                                items: 2 // Number of items to show on medium screens
                            },
                            1024: {
                                items: 4// Number of items to show on large screens
                            }
                        }}
                    >
                        <a href='/cctv' className='card-link'>
                            <Card className='services-card'>
                                <Card.Img variant="top" src='/Images/cctv-ser.jpg' />
                                <h4>CCTV Servivalence</h4>
                            </Card>
                        </a>

                        <a href='/network' className='card-link'>
                            <Card className='services-card'>
                                <Card.Img variant="top" src='/Images/networks.jpg' />
                                <h4>Network Survivalence</h4>
                            </Card>
                        </a>

                        <a href='/door' className='card-link'>
                            <Card className='services-card'>
                                <Card.Img variant="top" src='/Images/door-acess.jpg' />
                                <h4>Door Acess & Attendance System</h4>
                            </Card>
                        </a>

                        <a href='/gate' className='card-link'>
                            <Card className='services-card'>
                                <Card.Img variant="top" src='/Images/gate.jpg' />
                                <h4>Swing & Sliding gate</h4>
                            </Card>
                        </a>

                        <a href='/porch' className='card-link'>
                            <Card className='services-card'>
                                <Card.Img variant="top" src='/Images/car-proches.jpg' />
                                <h4>Tend & Car Porch</h4>
                            </Card>
                        </a>
                        <a href='/office' className='card-link'>
                            <Card className='services-card'>
                                <Card.Img variant="top" src='/Images/office.jpg' />
                                <h4>Office Equipments and Computers</h4>
                            </Card>
                        </a>

                    </OwlCarousel>
                </div>
            </div>
        </div>
    )
}

export default Network