import React from 'react';
import { Card, Container, Button } from 'react-bootstrap';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import './ProductList.css'

function ProductList() {
  const handleOnclick = () => {
    const phoneNumber = '+971505805291'; // Update the phone number format
    const message = 'Hello, I have a question!'; 
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    
    // Open WhatsApp chatbox in a new tab
    window.open(whatsappLink, '_blank');
};

  return (
    <div>

      <div className="product-heading">
        <h4>Our Products</h4>
        {/* <Container> */}
          <OwlCarousel
            autoplay
            loop
            items={4}
            margin={2}
            responsive={{
              0: {
                items: 1 // Number of items to show on small screens
              },
              768: {
                items: 2 // Number of items to show on medium screens
              },
              1024: {
                items: 4// Number of items to show on large screens
              }
            }}
          >
            <div className="card-items">
              <Card>
                <Card.Img variant="top" src="./Images/Products/office/office-2.jpg" alt='' className='w-100' />
                <Card.Body>
                  {/* <Card.Title>CCTV</Card.Title> */}
                  {/* <Card.Text>qwerty</Card.Text> */}
                </Card.Body>
                <Button onClick={handleOnclick}>Enquiry</Button>
              </Card>
            </div>

            <div className="card-items">
              <Card>
                <Card.Img variant="top" src="./Images/Products/door/door-2.jpg" className='w-100' />
                <Card.Body>
                  {/* <Card.Title>CCTV</Card.Title> */}
                  {/* <Card.Text>qwerty</Card.Text> */}
                </Card.Body>
                <Button onClick={handleOnclick}>Enquiry</Button>
              </Card>
            </div>

            <div className="card-items">
              <Card>
                <Card.Img variant="top" src="./Images/Products/printer.jpg" className='w-100' />
                <Card.Body>
                  {/* <Card.Title>CCTV</Card.Title> */}
                  {/* <Card.Text>qwerty</Card.Text> */}
                </Card.Body>
                <Button onClick={handleOnclick}>Enquiry</Button>
              </Card>
            </div>

            <div className="card-items">
              <Card>
                <Card.Img variant="top" src="/Images/Products/pd-1.jpeg" className='w-100' />
                <Card.Body>
                  {/* <Card.Title>CCTV</Card.Title> */}
                  {/* <Card.Text>qwerty</Card.Text> */}
                </Card.Body>
                <Button onClick={handleOnclick}>Enquiry</Button>
              </Card>
            </div>

            <div className="card-items">
              <Card>
                <Card.Img variant="top" src="/Images/Products/pd-2.jpeg" className='w-100' />
                <Card.Body>
                  {/* <Card.Title>CCTV</Card.Title> */}
                  {/* <Card.Text>qwerty</Card.Text> */}
                </Card.Body>
                <Button onClick={handleOnclick}>Enquiry</Button>
              </Card>
            </div>

          

            <div className="card-items">
              <Card>
                <Card.Img variant="top" src="/Images/Products/pd-9.jpg" className='w-100' />
                <Card.Body>
                  {/* <Card.Title>CCTV</Card.Title> */}
                  {/* <Card.Text>qwerty</Card.Text> */}
                </Card.Body>
                <Button onClick={handleOnclick}>Enquiry</Button>
              </Card>
            </div>
            
            <div className="card-items">
              <Card>
                <Card.Img variant="top" src="./Images/Products/office/office-13.jpg" className='w-100' />
                <Card.Body>
                </Card.Body>
                <Button onClick={handleOnclick}>Enquiry</Button>
              </Card>
            </div>

            <div className="card-items">
            <Card>
                <Card.Img variant="top" src="./Images/Products/door/door-6.jpg" />
                <Card.Body>
                </Card.Body>
                <Button onClick={handleOnclick}>Enquiry</Button>
              </Card>
            </div>

            <div className="card-items">
              <Card>
                <Card.Img variant="top" src="/Images/Products/office/office-8.jpg" className='w-100' />
                <Card.Body>
                  {/* <Card.Title>CCTV</Card.Title> */}
                  {/* <Card.Text>qwerty</Card.Text> */}
                </Card.Body>
                <Button onClick={handleOnclick}>Enquiry</Button>
              </Card>
            </div>

            <div className="card-items">
            <Card>
                <Card.Img variant="top" src="./Images/Products/door/door-5.jpg" />
                <Card.Body>
                </Card.Body>
                <Button onClick={handleOnclick}>Enquiry</Button>
              </Card>
            </div>

            <div className="card-items">
              <Card>
                <Card.Img variant="top" src="/Images/Products/office/office-4.jpg" className='w-100' />
                <Card.Body>
                  {/* <Card.Title>CCTV</Card.Title> */}
                  {/* <Card.Text>qwerty</Card.Text> */}
                </Card.Body>
                <Button onClick={handleOnclick}>Enquiry</Button>
              </Card>
            </div>

          </OwlCarousel>
        {/* </Container> */}

      </div>


      <div className="product-heading">
        <h4>Our Accessories</h4>
        {/* <Container> */}
          <OwlCarousel
            autoplay
            loop
            items={4}
            margin={2}
            responsive={{
              0: {
                items: 1 // Number of items to show on small screens
              },
              768: {
                items: 2 // Number of items to show on medium screens
              },
              1024: {
                items: 4// Number of items to show on large screens
              }
            }}
          >
            <div className="card-items">
              <Card>
                <Card.Img variant="top" src="/Images/Products/acc-1.jpeg" className='w-100' />
                <Card.Body>
                  {/* <Card.Title>CCTV</Card.Title> */}
                  {/* <Card.Text>qwerty</Card.Text> */}
                </Card.Body>
                <Button onClick={handleOnclick}>Enquiry</Button>
              </Card>
            </div>

            <div className="card-items">
              <Card>
                <Card.Img variant="top" src="/Images/Products/acc-2.jpeg" className='w-100' />
                <Card.Body>
                  {/* <Card.Title>CCTV</Card.Title> */}
                  {/* <Card.Text>qwerty</Card.Text> */}
                </Card.Body>
                <Button onClick={handleOnclick}>Enquiry</Button>
              </Card>
            </div>

            <div className="card-items">
              <Card>
                <Card.Img variant="top" src="/Images/Products/acc-3.jpg" className='w-100' />
                <Card.Body>
                  {/* <Card.Title>CCTV</Card.Title> */}
                  {/* <Card.Text>qwerty</Card.Text> */}
                </Card.Body>
                <Button onClick={handleOnclick}>Enquiry</Button>
              </Card>
            </div>

            <div className="card-items">
              <Card>
                <Card.Img variant="top" src="/Images/Products/acc-4.jpg" className='w-100' />
                <Card.Body>
                  {/* <Card.Title>CCTV</Card.Title> */}
                  {/* <Card.Text>qwerty</Card.Text> */}
                </Card.Body>
                <Button onClick={handleOnclick}>Enquiry</Button>
              </Card>
            </div>

            <div className="card-items">
              <Card>
                <Card.Img variant="top" src="/Images/Products/acc-5.jpg" className='w-100' />
                <Card.Body>
                  {/* <Card.Title>CCTV</Card.Title> */}
                  {/* <Card.Text>qwerty</Card.Text> */}
                </Card.Body>
                <Button onClick={handleOnclick}>Enquiry</Button>
              </Card>
            </div>

            <div className="card-items">
              <Card>
                <Card.Img variant="top" src="/Images/Products/acc-6.jpg" className='w-100' />
                <Card.Body>
                  {/* <Card.Title>CCTV</Card.Title> */}
                  {/* <Card.Text>qwerty</Card.Text> */}
                </Card.Body>
                <Button onClick={handleOnclick}>Enquiry</Button>
              </Card>
            </div>

            <div className="card-items">
              <Card>
                <Card.Img variant="top" src="/Images/Products/acc-7.jpg" className='w-100' />
                <Card.Body>
                  {/* <Card.Title>CCTV</Card.Title> */}
                  {/* <Card.Text>qwerty</Card.Text> */}
                </Card.Body>
                <Button onClick={handleOnclick}>Enquiry</Button>
              </Card>
            </div>

            <div className="card-items">
              <Card>
                <Card.Img variant="top" src="/Images/Products/acc-8.jpg" className='w-100' />
                <Card.Body>
                  {/* <Card.Title>CCTV</Card.Title> */}
                  {/* <Card.Text>qwerty</Card.Text> */}
                </Card.Body>
                <Button onClick={handleOnclick}>Enquiry</Button>
              </Card>
            </div>

            <div className="card-items">
              <Card>
                <Card.Img variant="top" src="/Images/Products/acc-9.jpg" className='w-100' />
                <Card.Body>
                  {/* <Card.Title>CCTV</Card.Title> */}
                  {/* <Card.Text>qwerty</Card.Text> */}
                </Card.Body>
                <Button onClick={handleOnclick}>Enquiry</Button>
              </Card>
            </div>

            <div className="card-items">
              <Card>
                <Card.Img variant="top" src="/Images/Products/acc-10.jpg" className='w-100' />
                <Card.Body>
                  {/* <Card.Title>CCTV</Card.Title> */}
                  {/* <Card.Text>qwerty</Card.Text> */}
                </Card.Body>
                <Button onClick={handleOnclick}>Enquiry</Button>
              </Card>
            </div>

            <div className="card-items">
              <Card>
                <Card.Img variant="top" src="/Images/Products/acc-11.jpg" className='w-100' />
                <Card.Body>
                  {/* <Card.Title>CCTV</Card.Title> */}
                  {/* <Card.Text>qwerty</Card.Text> */}
                </Card.Body>
                <Button onClick={handleOnclick}>Enquiry</Button>
              </Card>
            </div>

          </OwlCarousel>
        {/* </Container> */}
      </div>

      <div className="product-heading">
        <h4>Office Equipments And Computers</h4>
        {/* <Container> */}
          <OwlCarousel
            autoplay
            loop
            items={4}
            margin={2}
            responsive={{
              0: {
                items: 1 // Number of items to show on small screens
              },
              768: {
                items: 2 // Number of items to show on medium screens
              },
              1024: {
                items: 4// Number of items to show on large screens
              }
            }}
          >
            <div className="card-items">
              <Card>
                <Card.Img variant="top" src="./Images/Products/office/office-1.jpg" className='w-100' />
                <Card.Body>
                  {/* <Card.Title>CCTV</Card.Title> */}
                  {/* <Card.Text>qwerty</Card.Text> */}
                </Card.Body>
                <Button onClick={handleOnclick}>Enquiry</Button>
              </Card>
            </div>

            <div className="card-items">
              <Card>
                <Card.Img variant="top" src="./Images/Products/office/office-2.jpg" className='w-100' />
                <Card.Body>
                  {/* <Card.Title>CCTV</Card.Title> */}
                  {/* <Card.Text>qwerty</Card.Text> */}
                </Card.Body>
                <Button onClick={handleOnclick}>Enquiry</Button>
              </Card>
            </div>

            <div className="card-items">
              <Card>
                <Card.Img variant="top" src="./Images/Products/office/office-3.jpg" className='w-100' />
                <Card.Body>
                  {/* <Card.Title>CCTV</Card.Title> */}
                  {/* <Card.Text>qwerty</Card.Text> */}
                </Card.Body>
                <Button onClick={handleOnclick}>Enquiry</Button>
              </Card>
            </div>

            <div className="card-items">
              <Card>
                <Card.Img variant="top" src="./Images/Products/office/office-4.jpg" className='w-100' />
                <Card.Body>
                  {/* <Card.Title>CCTV</Card.Title> */}
                  {/* <Card.Text>qwerty</Card.Text> */}
                </Card.Body>
                <Button onClick={handleOnclick}>Enquiry</Button>
              </Card>
            </div>

            <div className="card-items">
              <Card>
                <Card.Img variant="top" src="./Images/Products/office/office-5.jpg" className='w-100' />
                <Card.Body>
                </Card.Body>
                <Button onClick={handleOnclick}>Enquiry</Button>
              </Card>
            </div>

            <div className="card-items">
              <Card>
                <Card.Img variant="top" src="/Images/Products/office/office-7.jpg" className='w-100' />
                <Card.Body>
                  {/* <Card.Title>CCTV</Card.Title> */}
                  {/* <Card.Text>qwerty</Card.Text> */}
                </Card.Body>
                <Button onClick={handleOnclick}>Enquiry</Button>
              </Card>
            </div>

            <div className="card-items">
              <Card>
                <Card.Img variant="top" src="/Images/Products/office/office-8.jpg" className='w-100' />
                <Card.Body>
                  {/* <Card.Title>CCTV</Card.Title> */}
                  {/* <Card.Text>qwerty</Card.Text> */}
                </Card.Body>
                <Button onClick={handleOnclick}>Enquiry</Button>
              </Card>
            </div>

            <div className="card-items">
              <Card>
                <Card.Img variant="top" src="./Images/Products/office/office-9.jpg" className='w-100' />
                <Card.Body>
                </Card.Body>
                <Button onClick={handleOnclick}>Enquiry</Button>
              </Card>
            </div>

            <div className="card-items">
              <Card>
                <Card.Img variant="top" src="./Images/Products/office/office-10.jpg" className='w-100' />
                <Card.Body>
                </Card.Body>
                <Button onClick={handleOnclick}>Enquiry</Button>
              </Card>
            </div>

            <div className="card-items">
              <Card>
                <Card.Img variant="top" src="./Images/Products/office/office-11.jpg" className='w-100' />
                <Card.Body>
                </Card.Body>
                <Button onClick={handleOnclick}>Enquiry</Button>
              </Card>
            </div>

            <div className="card-items">
              <Card>
                <Card.Img variant="top" src="./Images/Products/office/office-12.jpg" className='w-100' />
                <Card.Body>
                </Card.Body>
                <Button onClick={handleOnclick}>Enquiry</Button>
              </Card>
            </div>

            <div className="card-items">
              <Card>
                <Card.Img variant="top" src="./Images/Products/office/office-13.jpg" className='w-100' />
                <Card.Body>
                </Card.Body>
                <Button onClick={handleOnclick}>Enquiry</Button>
              </Card>
            </div>

            <div className="card-items">
              <Card>
                <Card.Img variant="top" src="./Images/Products/office/office-14.jpg" className='w-100' />
                <Card.Body>
                </Card.Body>
                <Button onClick={handleOnclick}>Enquiry</Button>
              </Card>
            </div>

            <div className="card-items">
              <Card>
                <Card.Img variant="top" src="./Images/Products/office/office-15.jpg" className='w-100' />
                <Card.Body>
                </Card.Body>
                <Button onClick={handleOnclick}>Enquiry</Button>
              </Card>
            </div>

            <div className="card-items">
              <Card>
                <Card.Img variant="top" src="./Images/Products/office/office-16.jpg" className='w-100' />
                <Card.Body>
                </Card.Body>
                <Button onClick={handleOnclick}>Enquiry</Button>
              </Card>
            </div>
          </OwlCarousel>
      </div>
  </div>
  );
}

export default ProductList;
