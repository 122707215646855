import React from 'react'
import './Products.css'
import { Container, Card } from 'react-bootstrap'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function Products() {

  const handleOnclick = () => {
    const phoneNumber = '+971505805291'; // Update the phone number format
    const message = 'Hello, I have a question!';
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

    window.open(whatsappLink, '_blank');
  };
  return (
    <div className='product-main-div'>
      <div className='product-div'>
        <img src='./Images/Banner/network-banner.jpg' alt='' className='product' />
        <h2>Providing the best cctv<br /> security</h2>
      </div>

      <div className='cards-product'>
        <h2>Our Products</h2>
        <Container className='products'>
          <h3>CCTV Systems</h3>
          <div>
            <OwlCarousel
              autoplay
              loop
              items={4}
              margin={2}
              responsive={{
                0: {
                  items: 1 // Number of items to show on small screens
                },
                768: {
                  items: 2 // Number of items to show on medium screens
                },
                1024: {
                  items: 4// Number of items to show on large screens
                }
              }}
            >
              <Card className='product-card'>
                <Card.Img variant="top" src="./Images/Products/pd-1.jpeg" />
                <Card.Body>
                  <Card.Title className='product-title' onClick={handleOnclick}>Enquiry</Card.Title>
                </Card.Body>
              </Card>

              <Card className='product-card'>
                <Card.Img variant="top" src="./Images/Products/pd-2.jpeg" />
                <Card.Body>
                  <Card.Title className='product-title' onClick={handleOnclick}>Enquiry</Card.Title>
                </Card.Body>
              </Card>

              <Card className='product-card'>
                <Card.Img variant="top" src="./Images/Products/pd-3.jpeg" />
                <Card.Body>
                  <Card.Title className='product-title' onClick={handleOnclick}>Enquiry</Card.Title>
                </Card.Body>
              </Card>

              <Card className='product-card'>
                <Card.Img variant="top" src="./Images/Products/pd-4.jpeg" />
                <Card.Body>
                  <Card.Title className='product-title' onClick={handleOnclick}>Enquiry</Card.Title>
                </Card.Body>
              </Card>
            </OwlCarousel>
          </div>
        </Container>

        <Container className='products'>
          <div>
            <h3>Network Solutions</h3>
            <OwlCarousel
              autoplay
              loop
              items={4}
              margin={2}
              responsive={{
                0: {
                  items: 1 // Number of items to show on small screens
                },
                768: {
                  items: 2 // Number of items to show on medium screens
                },
                1024: {
                  items: 4// Number of items to show on large screens
                }
              }}
            >
              <Card className='product-card'>
                <Card.Img variant="top" src="./Images/Products/acc-1.jpeg" style={{ padding: '20px' }} />
                <Card.Body>
                  <Card.Title className='product-title' onClick={handleOnclick}>Enquiry</Card.Title>
                </Card.Body>
              </Card>

              <Card className='product-card'>
                <Card.Img variant="top" src="./Images/Products/acc-2.jpeg" style={{ padding: '20px' }} />
                <Card.Body>
                  <Card.Title className='product-title' onClick={handleOnclick}>Enquiry</Card.Title>
                </Card.Body>
              </Card>

              <Card className='product-card'>
                <Card.Img variant="top" src="./Images/Products/acc-3.jpg" style={{ padding: '20px' }} />
                <Card.Body>
                  <Card.Title className='product-title' onClick={handleOnclick}>Enquiry</Card.Title>
                </Card.Body>
              </Card>

              <Card className='product-card'>
                <Card.Img variant="top" src="./Images/Products/acc-4.jpg" style={{ padding: '20px' }} />
                <Card.Body>
                  <Card.Title className='product-title' onClick={handleOnclick}>Enquiry</Card.Title>
                </Card.Body>
              </Card>
            </OwlCarousel>
          </div>
        </Container>


        <Container className='products'>
          <div>
            <h3>Door access and biometric attendance register</h3>
            <OwlCarousel
              autoplay
              loop
              items={4}
              margin={2}
              responsive={{
                0: {
                  items: 1 // Number of items to show on small screens
                },
                768: {
                  items: 2 // Number of items to show on medium screens
                },
                1024: {
                  items: 4// Number of items to show on large screens
                }
              }}
            >
              <Card className='product-card'>
                <Card.Img variant="top" src="./Images/Products/door/door-1.jpg" />
                <Card.Body>
                  <Card.Title className='product-title' onClick={handleOnclick}>Enquiry </Card.Title>
                </Card.Body>
              </Card>

              <Card className='product-card'>
                <Card.Img variant="top" src="./Images/Products/door/door-2.jpg" />
                <Card.Body>
                  <Card.Title className='product-title' onClick={handleOnclick}>Enquiry </Card.Title>
                </Card.Body>
              </Card>

              <Card className='product-card'>
                <Card.Img variant="top" src="./Images/Products/door/door-3.jpg" />
                <Card.Body>
                  <Card.Title className='product-title' onClick={handleOnclick}>Enquiry </Card.Title>
                </Card.Body>
              </Card>

              <Card className='product-card'>
                <Card.Img variant="top" src="./Images/Products/door/door-4.jpg" />
                <Card.Body>
                  <Card.Title className='product-title' onClick={handleOnclick}>Enquiry</Card.Title>
                </Card.Body>
              </Card>

              <Card className='product-card'>
                <Card.Img variant="top" src="./Images/Products/door/door-5.jpg" />
                <Card.Body>
                  <Card.Title className='product-title' onClick={handleOnclick}>Enquiry</Card.Title>
                </Card.Body>
              </Card>

              <Card className='product-card'>
                <Card.Img variant="top" src="./Images/Products/door/door-6.jpg" />
                <Card.Body>
                  <Card.Title className='product-title' onClick={handleOnclick}>Enquiry</Card.Title>
                </Card.Body>
              </Card>
            </OwlCarousel>
          </div>
        </Container>

        <Container className='products'>
          <div>
            <h3>Office Equipments And Computers</h3>
            <OwlCarousel
              autoplay
              loop
              items={4}
              margin={2}
              responsive={{
                0: {
                  items: 1 // Number of items to show on small screens
                },
                768: {
                  items: 2 // Number of items to show on medium screens
                },
                1024: {
                  items: 4// Number of items to show on large screens
                }
              }}
            >
              <Card className='product-card'>
                <Card.Img variant="top" src="./Images/Products/office/office-1.jpg" style={{ padding: '20px' }} />
                <Card.Body>
                  <Card.Title className='product-title' onClick={handleOnclick}>Enquiry</Card.Title>
                </Card.Body>
              </Card>

              <Card className='product-card'>
                <Card.Img variant="top" src="./Images/Products/office/office-2.jpg" style={{ padding: '20px' }} />
                <Card.Body>
                  <Card.Title className='product-title' onClick={handleOnclick}>Enquiry</Card.Title>
                </Card.Body>
              </Card>

              <Card className='product-card'>
                <Card.Img variant="top" src="./Images/Products/office/office-3.jpg" style={{ padding: '20px' }} />
                <Card.Body>
                  <Card.Title className='product-title' onClick={handleOnclick}>Enquiry</Card.Title>
                </Card.Body>
              </Card>

              <Card className='product-card'>
                <Card.Img variant="top" src="./Images/Products/office/office-4.jpg" style={{ padding: '20px' }} />
                <Card.Body>
                  <Card.Title className='product-title' onClick={handleOnclick}>Enquiry</Card.Title>
                </Card.Body>
              </Card>

              <Card className='product-card'>
                <Card.Img variant="top" src="./Images/Products/office/office-5.jpg" style={{ padding: '20px' }} />
                <Card.Body>
                  <Card.Title className='product-title' onClick={handleOnclick}>Enquiry</Card.Title>
                </Card.Body>
              </Card>

              <Card className='product-card'>
                <Card.Img variant="top" src="./Images/Products/office/office-6.jpg" style={{ padding: '20px' }} />
                <Card.Body>
                  <Card.Title className='product-title' onClick={handleOnclick}>Enquiry</Card.Title>
                </Card.Body>
              </Card>

              <Card className='product-card'>
                <Card.Img variant="top" src="./Images/Products/office/office-7.jpg" style={{ padding: '20px' }} />
                <Card.Body>
                  <Card.Title className='product-title' onClick={handleOnclick}>Enquiry</Card.Title>
                </Card.Body>
              </Card>

              <Card className='product-card'>
                <Card.Img variant="top" src="./Images/Products/office/office-8.jpg" style={{ padding: '20px' }} />
                <Card.Body>
                  <Card.Title className='product-title' onClick={handleOnclick}>Enquiry</Card.Title>
                </Card.Body>
              </Card>


              <Card className='product-card'>
                <Card.Img variant="top" src="./Images/Products/office/office-9.jpg" style={{ padding: '20px' }} className='w-100' />
                <Card.Body>
                  <Card.Title className='product-title' onClick={handleOnclick}>Enquiry</Card.Title>
                </Card.Body>
              </Card>


              <Card className='product-card'>
                <Card.Img variant="top" src="./Images/Products/office/office-10.jpg"  style={{ padding: '20px' }} className='w-100' />
                <Card.Body>
                  <Card.Title className='product-title' onClick={handleOnclick}>Enquiry</Card.Title>
                </Card.Body>
              </Card>


              <Card className='product-card'>
                <Card.Img variant="top" src="./Images/Products/office/office-11.jpg"  style={{ padding: '20px' }} className='w-100' />
                <Card.Body>
                  <Card.Title className='product-title' onClick={handleOnclick}>Enquiry</Card.Title>
                </Card.Body>
              </Card>


              <Card className='product-card'>
                <Card.Img variant="top" src="./Images/Products/office/office-12.jpg"  style={{ padding: '20px' }} className='w-100' />
                <Card.Body>
                  <Card.Title className='product-title' onClick={handleOnclick}>Enquiry</Card.Title>
                </Card.Body>
              </Card>


              <Card className='product-card'>
                <Card.Img variant="top" src="./Images/Products/office/office-13.jpg"  style={{ padding: '20px' }} className='w-100' />
                <Card.Body>
                  <Card.Title className='product-title' onClick={handleOnclick}>Enquiry</Card.Title>
                </Card.Body>
              </Card>

              <Card className='product-card'>
                <Card.Img variant="top" src="./Images/Products/office/office-14.jpg"  style={{ padding: '20px' }} className='w-100' />
                <Card.Body>
                  <Card.Title className='product-title' onClick={handleOnclick}>Enquiry</Card.Title>
                </Card.Body>
              </Card>

              <Card className='product-card'>
                <Card.Img variant="top" src="./Images/Products/office/office-15.jpg"  style={{ padding: '20px' }} className='w-100' />
                <Card.Body>
                  <Card.Title className='product-title' onClick={handleOnclick}>Enquiry</Card.Title>
                </Card.Body>
              </Card>

              <Card className='product-card'>
                <Card.Img variant="top" src="./Images/Products/office/office-16.jpg"  style={{ padding: '20px' }} className='w-100' />
                <Card.Body>
                  <Card.Title className='product-title' onClick={handleOnclick}>Enquiry</Card.Title>
                </Card.Body>
              </Card>

            </OwlCarousel>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default Products