import React, { useState } from 'react'
import './Contact.css'
import { Button, Col, Form, Row } from 'react-bootstrap'

function Contact() {

  const handleEmailClick = (email) => {
    window.location.href = `mailto:${email}`;
  };

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://localhost:3000/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      // if (response.ok) {
      //   alert('Form submitted successfully!');
      //   // Optionally, you can reset the form fields here
      // } else {
      //   alert('Failed to submit form. Please try again later.');
      // }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <div className='contact-main'>
      <div className='contact-main-div'>
        <img src='./Images/contact-banner.jpg' alt='' />
        <h1>Contact Us</h1>
      </div>
      <div className='contacts'>
        <Row>
          <Col></Col>
          <Col xs={12} md={3}>
            <div className='contact-items'>
              <img src='./Images/contact.png' alt='' />
              <h4>Give us a call</h4>
              <p>0505805291
                <br /> <br />+971 2 67 67 921 </p>
            </div>
          </Col>
          <Col xs={12} md={3}>
            <div className='contact-items'>
              <img src='./Images/mail.png' alt='' />
              <h4>Drop us a line</h4>
              <p className='email' onClick={() => handleEmailClick('info@oceanwavesad.com')}>info@oceanwavesad.com</p> 
              <p className='email' onClick={() => handleEmailClick('oceanwaves999@hotmail.com')}>oceanwaves999@hotmail.com</p>
            </div>
          </Col>
          <Col xs={12} md={3}>
            <div className='contact-items'>
          <a href='/Images/mapshop.jpg'><img src='./Images/location.png' alt='' /></a>  
              <h4>Visit our office</h4>
              <p style={{fontSize:"15.7px"}}> Near Lulu Xpress Hypermarket,Hamdan St. <br />
                P.O Box: 107183, Abu Dhabi - U.A.E.</p>
            </div>
          </Col>
          <Col></Col>
        </Row>
      </div>

      <center>
        <div className='forms'>
          <p>Request a quote</p>
          <h4>How May We Help You!</h4>
          <Form onSubmit={handleFormSubmit}>
            <Row>
              <Col xs={12} md={6}>
                <input
                  placeholder='Name'
                  className='name'
                  name='name'
                  onChange={handleInputChange}
                />
              </Col>
              <Col xs={12} md={6}>
                <input
                  placeholder='Email'
                  className='name'
                  name='email'
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
            <input
              placeholder='Subject'
              className='subject'
              name='subject'
              onChange={handleInputChange}
            /> <br />
            <input
              placeholder='Write a Message'
              className='message'
              name='message'
              onChange={handleInputChange}
            />
            <Button type='submit' className='msg-btn'>Send Message</Button>

          </Form>
        </div>
      </center>
      <div className='map-div'>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d22926.9087729804!2d54.37456868526125!3d24.503059512514007!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5e665dca2ff087%3A0x7bcf747a0b8431f0!2sOCEAN%20WAVES%20SECURITY%20SYSTEMS!5e0!3m2!1sen!2sae!4v1700725389813!5m2!1sen!2sae"
          width="100%"
          height="650"
          style={{ border: 0, marginTop: '-8%' }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>

      </div>
    </div>
  )
}

export default Contact