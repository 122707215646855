import React from 'react'
import './Door.css'
import { Row, Col, Card } from 'react-bootstrap'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function Door() {
    return (
        <div>
            <div className='door-div'>
                <img src='/Images/Banner/door-banner.jpg' alt='' className='service' />
                <h2>Enhancing Security and Efficiency</h2>
            </div>

            <div className='object-div'>
                <h1>Door Access and biometric time Attendance</h1>
                <div className='description'>
                    <Row>
                        <Col xs={12} md={6} >
                            <img src='/Images/services/door-1.jpg' alt='' style={{ height: '600px' }} />
                            <img src='/Images/services/door-2.jpg' alt='' style={{ height: '600px' }} />

                        </Col>
                        <Col xs={12} md={6}>
                            <h2>Access Cards</h2>
                            <p>The access card may be thought of as an electronic
                                "key". The access card is used by persons to gain
                                access through the doors secured by the access control
                                system. Each access card is uniquely encoded. Most
                                access cards are approximately the same size as a
                                standard credit card, and can easily be carried in a
                                wallet or purse </p>
                            <br />
                            <h2>Card Readers</h2>
                            <p>Card readers are the devices used to electronically
                                "read" the access card. Card readers may be of the
                                "insertion" type (which require insertion of the card into
                                the reader), or may be of the "proximity" type (which
                                only require that the card be held in a 3" to 6" proximity
                                of the reader.) Card readers are usually mounted on the
                                exterior (non-secured) side of the door that they
                                control.</p>
                            <h2>Access Control Keypads</h2>
                            <p>Access control keypads are devices which may be used
                                in addition to or in place of card readers. The access
                                control keypad has numeric keys which look similar to
                                the keys on a touch-tone telephone.
                                The access control keypad requires that a person
                                desiring to gain access enter a correct numeric code.
                                When access control keypads are used in addition to
                                card readers, both a valid card and the correct code
                                must presented before entry is allowed.</p>

                        </Col>
                    </Row>
                </div>
                <div className='types-of-service'>
                    <h1>Types of services</h1>

                    <OwlCarousel
                        autoplay
                        loop
                        items={4}
                        margin={2}
                        responsive={{
                            0: {
                                items: 1 // Number of items to show on small screens
                            },
                            768: {
                                items: 2 // Number of items to show on medium screens
                            },
                            1024: {
                                items: 4// Number of items to show on large screens
                            }
                        }}
                    >
                        <a href='/cctv' className='card-link'>
                            <Card className='services-card'>
                                <Card.Img variant="top" src='/Images/cctv-ser.jpg' />
                                <h4>CCTV Servivalence</h4>
                            </Card>
                        </a>

                        <a href='/network' className='card-link'>
                            <Card className='services-card'>
                                <Card.Img variant="top" src='/Images/networks.jpg' />
                                <h4>Network Survivalence</h4>
                            </Card>
                        </a>

                        <a href='/door' className='card-link'>
                            <Card className='services-card'>
                                <Card.Img variant="top" src='/Images/door-acess.jpg' />
                                <h4>Door Acess & Attendance System</h4>
                            </Card>
                        </a>

                        <a href='/gate' className='card-link'>
                            <Card className='services-card'>
                                <Card.Img variant="top" src='/Images/gate.jpg' />
                                <h4>Swing & Sliding gate</h4>
                            </Card>
                        </a>

                        <a href='/porch' className='card-link'>
                            <Card className='services-card'>
                                <Card.Img variant="top" src='/Images/car-proches.jpg' />
                                <h4>Tend & Car Porch</h4>
                            </Card>
                        </a>

                        <a href='/office' className='card-link'>
                            <Card className='services-card'>
                                <Card.Img variant="top" src='/Images/office.jpg' />
                                <h4>Office Equipments and Computers</h4>
                            </Card>
                        </a>
                    </OwlCarousel>
                </div>
            </div>
        </div>
    )
}

export default Door