import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Topbar from './Components/Topbar/Topbar';
import Banner from './Components/Banner/Banner';
import Footer from './Components/Footer/Footer';
import { Route, Routes } from 'react-router';
import About from './Components/InnerPages/AboutPage/About';
import Cctv from './Components/InnerPages/Services/CCTV/Cctv';
import Gate from './Components/InnerPages/Services/Gate/Gate';
import Contact from './Components/InnerPages/Contact/Contact';
import Products from './Components/InnerPages/Products/Products';
import Network from './Components/InnerPages/Services/Network/Network';
import Door from './Components/InnerPages/Services/Door/Door';
import Porch from './Components/InnerPages/Services/Porch/Porch';
import ContactBar from './Components/ContactBar/ContactBar';
import Office from './Components/InnerPages/Services/Office/Office';

function App() {
  return (
    <div className="App">
      <Topbar />

      <Routes>
        <Route path='/' element={<Banner />} />
        <Route path='/about' element={<About />} />

        <Route path='/cctv' element={<Cctv />} />
        <Route path='/gate' element={<Gate />} />
        <Route path='/network' element={<Network />} />
        <Route path='/door' element={<Door />} />
        <Route path='/porch' element={<Porch />} />
        <Route path='office' element={<Office />} />

        <Route path='/contact' element={<Contact />} />
        <Route path='/product' element={<Products />} />
      </Routes>
      <ContactBar />
      <Footer />
    </div>
  );
}

export default App;
