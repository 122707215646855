import React from 'react';
import './ContactBar.css';

function ContactBar() {
    const handleWhatsappClick = () => {
        const phoneNumber = '+971505805291'; // Update the phone number format
        const message = 'Hello, I have a question!'; 
        const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        
        window.open(whatsappLink, '_blank');
    };

    const handlePhoneClick = () => {
        const phoneNumber = '+971505805291';
        
        window.open(`tel:${phoneNumber}`);
    };

    return (
        <div className="floating-icons">
            <div className='icon-grp' onClick={handleWhatsappClick}>
                <img src='/Images/icons/whatsapp.png' className='whats'style={{width:"100%"}}/>
            </div>
            <div className='icon-grp' onClick={handlePhoneClick}>
                <img src='/Images/icons/contact.png'  className='whats' style={{width:"90%",height:"49px"}}/>
            </div>
        </div>
    );
}

export default ContactBar;
