import React from 'react'
import './Office.css'
import { Row, Col, Card } from 'react-bootstrap'

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function Office() {
    return (
        <div >
            <div className='network-div'>
                <img src='/Images/services/office.jpg' alt='' className='service' />
                {/* <h2>Seamless Connectivity</h2> */}
            </div>

            <div className='object-div'>
                <h1>OFFICE EQUIPMENTS AND COMPUTERS</h1>
                <div className='description'>
                    <Row>
                        <Col xs={12} md={6} >
                            <img src='/Images/office.jpg' alt='' className='network-img' />
                        </Col>
                        <Col xs={12} md={6}>
                            <p className='net-p' style={{ lineHeight: '4rem' }}>
                                Information is the lifeblood of any successful organization.
                                The key to leveraging information to your advantage is purchasing
                                and implementing the right computers and software for your business.
                                You will also want to explore systems for backing up your business data.
                                There are many online services available now that will save you from having
                                to invest in secure and reliable on-site backup equipment for your office.</p>
                        </Col>
                    </Row>
                </div>
                <div className='types-of-service'>
                    <h1>Types of services</h1>

                    <OwlCarousel
                        autoplay
                        loop
                        items={4}
                        margin={2}
                        responsive={{
                            0: {
                                items: 1 // Number of items to show on small screens
                            },
                            768: {
                                items: 2 // Number of items to show on medium screens
                            },
                            1024: {
                                items: 4// Number of items to show on large screens
                            }
                        }}
                    >
                        <a href='/cctv' className='card-link'>
                            <Card className='services-card'>
                                <Card.Img variant="top" src='/Images/cctv-ser.jpg' />
                                <h4>CCTV Servivalence</h4>
                            </Card>
                        </a>

                        <a href='/network' className='card-link'>
                            <Card className='services-card'>
                                <Card.Img variant="top" src='/Images/networks.jpg' />
                                <h4>Network Survivalence</h4>
                            </Card>
                        </a>

                        <a href='/door' className='card-link'>
                            <Card className='services-card'>
                                <Card.Img variant="top" src='/Images/door-acess.jpg' />
                                <h4>Door Acess & Attendance System</h4>
                            </Card>
                        </a>

                        <a href='/gate' className='card-link'>
                            <Card className='services-card'>
                                <Card.Img variant="top" src='/Images/gate.jpg' />
                                <h4>Swing & Sliding gate</h4>
                            </Card>
                        </a>

                        <a href='/porch' className='card-link'>
                            <Card className='services-card'>
                                <Card.Img variant="top" src='/Images/car-proches.jpg' />
                                <h4>Tend & Car Porch</h4>
                            </Card>
                        </a>
                        <a href='/office' className='card-link'>
                            <Card className='services-card'>
                                <Card.Img variant="top" src='/Images/office.jpg' />
                                <h4>Office Equipments and Computers</h4>
                            </Card>
                        </a>

                    </OwlCarousel>
                </div>
            </div>
        </div>
    )
}

export default Office