import React from 'react'
import './Gate.css'
import { Row, Col, Card } from 'react-bootstrap'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function Gate() {
  return (
    <div>
      <div className='gate-div'>
        <img src='/Images/Banner/gate-banner.jpg' alt='' className='service' />
      </div>

      <div className='object-div'>
        <h1>Swing and Sliding gate</h1>
        <div className='description'>
          <Row>
            <Col xs={12} md={6} >
              <img src='/Images/gate-main.jpg' alt='' />
            </Col>
            <Col xs={12} md={6}>
              <p>OceanWaves is your premier partner in Security
                Gate Services, delivering cutting-edge solutions
                to fortify the entrance points of your premises.
                Our specialized security gate services are designed
                to enhance overall safety and access control for
                businesses. We provide a range of options, from
                traditional physical barriers to technologically
                advanced automated gates, ensuring a customized
                solution that aligns with your security requirements.
                <br />
                OceanWaves' Security Gate Services prioritize both security
                and convenience. Our automated gates feature state-of-the-art
                technologies, allowing for seamless integration with access
                control systems, including key cards, biometric authentication,
                and remote monitoring. This not only strengthens your security
                posture but also provides a user-friendly experience for authorized personnel.
                With a focus on reliability and innovation, OceanWaves ensures that your
                Security Gate Services meet the highest standards of quality.
                Our team of experts is dedicated to providing comprehensive solutions
                that safeguard your premises effectively.
              </p>
            </Col>
          </Row>
        </div>


        <div className='gate-services'>
          <h3>We Provide more services to you</h3>
          <h2>Swing gate</h2>
          <Row>
            <Col xs={12} md={6} >
              <img src='./Images/services/swing.jpg' alt='' style={{marginTop:"30px"}}/>
            </Col>
            <Col xs={12} md={6}>
              <p> Swing gate openers are designed for large-sized Swing
                gates, and such a system is highly reliable. Due to their
                great weight, Swing gates require a heavy-duty opener
                appropriate for their dimensions. This type of mechanism
                opens both gates simultaneously, which is very conve-
                nient and time-saving. Another strength of dual gate
                openers is their versatility. You can use them to open a
                single gate as well, Your looking for new openers for your
                villa gate or your factory gate, or you facing issues with
                your existing Automatic gate system.
                The material and performance of outdoor swing gates
                and openers must be built to resist a variety of working
                circumstances, including rain, wind, cold, and heat. The
                use of high-quality, low-maintenance materials ensures
                the lifetime and trouble-free operation of our openers,
                which is something we aim for. Our outdoor gate openers
                are always designed to blend in with your gate for a
                fashionable, modern appearance.</p>
            </Col>
          </Row>

          <h2>Sliding gate</h2>
          <Row>
            <Col xs={12} md={6}>
              <p> Sliding gate openers are the most common and convenient
                solution for driveway gates. You can safely park your car
                close to the gates, without exposing the vehicle to the risk
                of being hit. This is because sliding gates open sidewards,
                leaving more room on your driveway. Besides, sliding gates
                are more secure and more difficult to force open. We have
                professional technical team for New Automatic gates
                installations and maintenance of automatic gates. <br />
                • Reverse Motion <br />
                • Easy Installation <br />
                • Low Maintenance <br />
                • Superior Strength <br />
                • Guide Rail/Roller <br />
                • Precision Bearings <br />
                • Photocell Safety Beams <br />
                • Safety Loop Detectors <br />
                • Force Limitation Safety <br />
                • Drive Gear Motor</p>
            </Col>
            <Col xs={12} md={6} >
              {/* <img src='./Images/services/sliding.jpg' alt='' /> */}
              <img src='./Images/sliding.png' alt='' />

            </Col>
          </Row>

          <h2>SHUTTER DOORS</h2>
          <Row>
            <Col xs={12} md={6} >
              <img src='./Images/shutter.png' alt='' />
            </Col>
            <Col xs={12} md={6}>
              <p> The shutter is an access door or entrance door for storage
                application or perimeter building security. The shutters
                come with different versions of drive units with push
                button controls and crank override. Various kind of rolling
                shutter including horizontal sliding, fire rated, and
                insulated shutters are supplied in various styles and
                designs. The major advantage of installing roller shutter is
                security from intruders. Rolling shutters are hard to
                remove as they are closely fitted and cannot be removed
                away easily. < br />
                • High Handling Capacity <br />
                • High Operation Speed  <br />
                • Speed Regulation  <br />
                • Operational Safety  <br />
                • Self Restoring  <br />
                • Automatic Lock  <br />
                • Design Modularity  <br />
                • Optical Protection Switch  <br />
                • Foam Insulated  <br />
                • Extruded Profile  <br />
                • Tubular Motor  <br />
                • Sensors </p>
            </Col>
          </Row>

          <h2>SECTIONAL OVER HEAD DOORS</h2>
          <Row>
            <Col xs={12} md={6}>
              <p> With Ocean Waves Security System, you
                can rest assured knowing that your
                purchase is backed by experienced
                professionals. Our extensive selection of
                overhead sectional doors provides you
                with the perfect fit for your needs. <br />
                • Heat &Sound Insulation  <br />
                • Wind Proof <br />
                • Rope/Spring Break <br />
                • High-Grade Material <br />
                • Anti-corrosion Treatment <br />
                • Infrared/Radar Sensors <br />
                • Magnetic Loops <br />
                • Low Noise Operation <br />
                • Extreme Robustness <br />
                • Control System <br />
                </p>
            </Col>
            <Col xs={12} md={6} >
              <img src='./Images/sectional.png' alt='' />
            </Col>
          </Row>

          <h2>SLIDING GLASS DOORS</h2>
          <Row>
            <Col xs={12} md={6} >
              <img src='./Images/services/glass-door.jpg' alt='' />
              <img src='./Images/services/glass-door2.jpg' alt=''  style={{marginTop:"20px"}}/>

            </Col>
            <Col xs={12} md={6}>
              <p> Sliding glass doors are a great, space-saving option for your
                backyard, patio or deck. They slide open while staying flush
                with your wall, so you can gain quick access to the outdoors,
                even in tight spaces. Sliding glass doors can be a beautiful
                home addition for you and your family. There are many benefits
                to installing these aesthetically pleasing doors in the home.
                They maximize natural lighting, increase airflow, and facilitate
                the flow of traffic to and from the house. <br />
                • High Traffic Entrance <br />
                • Energy Efficient <br />
                • Weather Sweeps <br />
                • Pushbars <br />
                • Speed Control <br />
                • Automatic Drive System <br />
                • Large Diameter <br />
                • Modular Components <br />
                • Trouble Free Installation <br />
                • Outstanding Safety</p>
            </Col>
          </Row>

        </div>




        <div className='types-of-service'>
          <h1>Types of services</h1>

          <OwlCarousel
            autoplay
            loop
            items={4}
            margin={2}
            responsive={{
              0: {
                items: 1 // Number of items to show on small screens
              },
              768: {
                items: 2 // Number of items to show on medium screens
              },
              1024: {
                items: 4// Number of items to show on large screens
              }
            }}
          >
            <a href='/cctv' className='card-link'>
              <Card className='services-card'>
                <Card.Img variant="top" src='/Images/cctv-ser.jpg' />
                <h4>CCTV Servivalence</h4>
              </Card>
            </a>

            <a href='/network' className='card-link'>
              <Card className='services-card'>
                <Card.Img variant="top" src='/Images/networks.jpg' />
                <h4>Network Survivalence</h4>
              </Card>
            </a>

            <a href='/door' className='card-link'>
              <Card className='services-card'>
                <Card.Img variant="top" src='/Images/door-acess.jpg' />
                <h4>Door Acess & Attendance System</h4>
              </Card>
            </a>

            <a href='/gate' className='card-link'>
              <Card className='services-card'>
                <Card.Img variant="top" src='/Images/gate.jpg' />
                <h4>Swing & Sliding gate</h4>
              </Card>
            </a>

            <a href='/porch' className='card-link'>
              <Card className='services-card'>
                <Card.Img variant="top" src='/Images/car-proches.jpg' />
                <h4>Tend & Car Porch</h4>
              </Card>
            </a>
            <a href='/office' className='card-link'>
              <Card className='services-card'>
                <Card.Img variant="top" src='/Images/office.jpg' />
                <h4>Office Equipments and Computers </h4>
              </Card>
            </a>

          </OwlCarousel>
        </div>
      </div>
    </div>
  )
}

export default Gate