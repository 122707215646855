import React from 'react';
import './About.css';
import { Col, Container, Row } from 'react-bootstrap';

function About() {
  return (
    <div className='about-div'>
      <Container className='white-div'>
        <Row>
          <Col xs={12} md={6}>
            <img src='./Images/about-img.jpg' alt='' className='about-img' />
          </Col>
          <Col xs={12} md={6}>
            <div className='about-text'>
              <h1>About Our Company</h1>
              <p>
                <span style={{ fontWeight: 600 }}>Ocean Waves Security Systems </span> was established in the year 2006 in Abu Dhabi,
                the capital of United Arab Emirates.
                <br />
                After all these years of successful business
                due to the continuous support from our valued customers, we are encouraged to
                make this Company Profile, which includes the complete details of the products
                and services we provide . together with the latest information about our company.
                Therefore, we have included many new products to offer you a wider selection.


              </p>
            </div>
          </Col>
        </Row>
      </Container>

      <Container className='boxes'>
        <Row>
          <Col xs={12} md={4}>
            <div
              className='box1 container'
              style={{ backgroundColor: 'rgb(126,168,165)' }}
            >
              <h1>01</h1>
              <p>
                We would also like to take this opportunity to thank all our existing customers 
                for making our Company and it's Products and Services a tremendous success. 
                You have my personal commitment along with that of my team that at Ocean Waves 
                Security Systems Est. you will always save on your Computer Service and Office supplies bills.

                <br />
                This means more than just lowering prices on over 6,000 products that we stock 
                and sell and that are used in your offices daily.  We are committed to providing 
                the best solutions for your office needs, through outstanding customer service, 
                fast turnaround times and new products to meet the needs of a fast changing office environment.
              </p>
            </div>
          </Col>

          <Col xs={12} md={4}>
            <div
              className='box2 container'
              style={{
                backgroundColor: 'rgb(213, 216, 216)',
                color: 'black',
                paddingBottom:"65px"
              }}
            >
              <h1>02</h1>
              <p>
              Ocean Waves Security Systems was established to serve the needs of computer users, 
              and today we are one of the industry's top computer and computer-product retailers. 
              Our success has been built on a simple principle: take care of every customer like 
              they were a member of our family. From the beginning, our top priority was to provide 
              unmatched customer care and to help our customers understand how technology could help 
              them. We've always invited intelligent, courteous men and women to staff our call center; 
              to answer your questions, make recommendations and deliver solutions.
              </p>
            </div>
          </Col>

          <Col xs={12} sm={12} md={4} >
            <div className='box3 container' style={{ backgroundColor: 'rgb(19,19,104' }}>
              <h1>03</h1>
              <p> We stock our web site 
              with the latest products, the best deals---and plenty of information to help you decide for 
              yourself. We are proud of each and every member of our staff, because they make the difference.
              Just because we offer the industry's lowest prices doesn't mean we skimp on service. In fact, 
              it is because we take care of our customers—and you continue to support us with your 
              business—that we're able to use our buying power to get you the best deals.


              So when you call us or log onto our web site, you're doing business with a company that is 
              enthusiastic about computers, and about matching our customers with the items that they need. 
            

              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default About;
