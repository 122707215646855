import React from 'react'
import './Footer.css'
import { Col, Row } from 'react-bootstrap'
import { FaMapMarkerAlt, FaPhone, FaEnvelope } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp, faInstagram, faFacebook, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';

function Footer() {
  return (
    <div className='footer-div'>
      <Row>
        <Col xs={12} md={4}>
          <img src='/Images/ocean-waves.png' alt='' className='footer-logo' />
          <p className='footer-text'>Ocean Waves Security Systems  was established in the year 2006 in Abu Dhabi,
            the capital of United Arab Emirates.</p>
        </Col>
        <Col xs={12} md={4}>
          <div className='footer-content'>
            <h4>Contact Us</h4>
            <div className="contact-container">
              <div className="icon-container">
                <div className="icon-contact">
                  <FaMapMarkerAlt />
                </div>
                <div className="details">Near Lulu Xpress Hypermarket, Hamdan St.
                  P.O Box: 107183, Abu Dhabi - U.A.E.</div>
              </div>

              <div className="icon-container">
                <div className="icon-contact">
                  <FaPhone />
                </div>
                <div className="details">Phone: +971 2 67 67 921</div>
              </div>

              <div className="icon-container">
                <div className="icon-contact">
                  <FaEnvelope />
                </div>
                <div className="details">Email: info@oceanwavesad.com</div>
              </div>
            </div>
          </div>
        </Col>


        <Col xs={12} md={4}>
        <h4 className='follow'>Follow Us</h4>
          <div className="social-container">
            {/* <div className="icon-container">
              <div className="icon">
            <a href=''><FontAwesomeIcon icon={faWhatsapp} style={{ width: "100%" }} /></a>    
              </div>
            </div> */}

            <div className="icon-container">
              <div className="icon">
               <a href='https://www.instagram.com/abdul.gaffur_c_p?utm_source=qr'><FontAwesomeIcon icon={faInstagram}/></a> 
              </div>
            </div>

            <div className="icon-container">
              <div className="icon">
              <a href='https://www.facebook.com/oceanwaves990?mibextid=LQQJ4d'><FontAwesomeIcon icon={faFacebook} /></a>  
              </div>
            </div>

            <div className="icon-container">
              <div className="icon">
              <a href='https://www.linkedin.com/posts/abdul-gaffur-chaliparambil-6b187b293_network-and-security-activity-7141666476272377857-XP23?utm_source=share&utm_medium=member_ios'><FontAwesomeIcon icon={faLinkedin} /></a>  
              </div>
            </div>
          </div>
          <div>
          <img src='/Images/footer/qr.jpg' alt='' />
            <p className='qr-code'>For More Details, Scan QR Code</p>
          </div>
        </Col>
      </Row>

      <p className='copyright'>© Oceanwaves 2023   |  <span style={{color:'#b1a8a89c',marginLeft:'2px'}}>  Design:</span> SYSBREEZE </p>

    </div>
  )
}

export default Footer