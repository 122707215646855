import React from 'react'
import './Topbar.css'
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom';

function Topbar() {
  return (
    // <Container>
    <div className='topbar'>
      <Navbar variant="light" expand="lg" className="navbar sticky-top topbar-main">
        <Container fluid>
          <div className='logo-div'>
         <Link to='/'><img src='/Images/ocean-waves.png' className='logo mt-3' alt=''></img></Link>   
          </div>
          <Navbar.Brand href="#" className='heading'>
            <span className='span-arabi'> ‫اﻣﻮاج‬ ‫اﻟﻤﺤﻴﻂ‬ ‫ﻟﻼﻧﻈﻤﺔ‬ ‫اﻻﻣﻨﻴﺔ‬ .‫م‬.‫م‬.‫ذ‬ </span>
            <br /> Ocean Waves Security System L.L.C.</Navbar.Brand>
          <Navbar.Toggle aria-controls="navbar-nav" />

          <Navbar.Collapse id="navbar-nav ">
            <Nav className="ms-auto nav-items">
              <Nav.Link href="/" style={{ color: "white" }}>Home</Nav.Link>
              <Nav.Link href="/about" style={{ color: "white" }}>About us</Nav.Link>
              <NavDropdown style={{ color: "white" }} title="Services" id="basic-nav-dropdown" >
                <NavDropdown.Item href="/cctv">Security & Survivalence</NavDropdown.Item>
                <NavDropdown.Item href="/network">
                  Network Solutions
                </NavDropdown.Item>
                <NavDropdown.Item href="/door">Door Access & biometric time attendance</NavDropdown.Item>
                <NavDropdown.Item href="/gate">Swing & Sliding Gates</NavDropdown.Item>
                <NavDropdown.Item href="/porch">Tend & Car Porches</NavDropdown.Item>
                <NavDropdown.Item href='/office'>OFFICE EQUIPMENTS AND COMPUTERS</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="/product" style={{ color: "white" }}>Products</Nav.Link>
              <Nav.Link href="/contact" style={{ color: "white" }}>Contact</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  )
}

export default Topbar