import React from 'react'
import './cctv.css'
import { Row, Col, Card } from 'react-bootstrap'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function Cctv() {

    return (
        <div>
            <div className='banner-div'>
                <img src='./Images/Banner/cctv-banner.jpg' alt='' className='service' />
                <h2>Providing the best cctv<br /> security</h2>
            </div>

            <div className='object-div'>
                <h1>CCTV CAMERAS</h1>
                <div className='description'>
                    <Row>
                        <Col xs={12} md={6} >
                            <img src='/Images/services/cctv.jpg' alt='' />
                        </Col>
                        <Col xs={12} md={6}>
                            <p>Get the ultimate security for your business or home
                                premises with our advanced CCTV and Access control
                                system in Dubai, UAE. Our sophisticated camera
                                monitoring and access-controls will give you peace of
                                mind knowing that your property is safe and secure
                                from any threats or intrusions. Let us help you fortify
                                your premises with a tailor-made solution to fit all of
                                your needs. Take advantage of our expertise and
                                experience, and enjoy complete protection with
                                customized solutions that go beyond the ordinary!
                                We are always promised to provide impeccable
                                customer service and support. We are selling the
                                leading CCTV brand cameras like HikVision, UNV,
                                Dahua, etc. We have a bunch of skilled certified CCTV
                                professionals to install CCTV surveillance cameras in
                                your home, villa, or business. Our experience and
                                expertise in CCTV help us understand the client’s
                                concerns and requirements for the safety and security
                                of their premises quickly and deliver the optimum
                                result to satisfy them.</p>
                        </Col>
                    </Row>
                </div>
                <div className='types-of-service'>
                    <h1>Types of services</h1>

                    <OwlCarousel
                        autoplay
                        loop
                        items={4}
                        margin={2}
                        responsive={{
                            0: {
                                items: 1 // Number of items to show on small screens
                            },
                            768: {
                                items: 2 // Number of items to show on medium screens
                            },
                            1024: {
                                items: 4// Number of items to show on large screens
                            }
                        }}
                    >
                        <a href='/cctv' className='card-link'>
                            <Card className='services-card'>
                                <Card.Img variant="top" src='/Images/cctv-ser.jpg' />
                                <h4>CCTV Servivalence</h4>
                            </Card>
                        </a>

                        <a href='/network' className='card-link'>
                            <Card className='services-card'>
                                <Card.Img variant="top" src='/Images/networks.jpg' />
                                <h4>Network Survivalence</h4>
                            </Card>
                        </a>

                        <a href='/door' className='card-link'>
                            <Card className='services-card'>
                                <Card.Img variant="top" src='/Images/door-acess.jpg' />
                                <h4>Door Acess & Attendance System</h4>
                            </Card>
                        </a>

                        <a href='/gate' className='card-link'>
                            <Card className='services-card'>
                                <Card.Img variant="top" src='/Images/gate.jpg' />
                                <h4>Swing & Sliding gate</h4>
                            </Card>
                        </a>

                        <a href='/porch' className='card-link'>
                            <Card className='services-card'>
                                <Card.Img variant="top" src='/Images/car-proches.jpg' />
                                <h4>Tend & Car Porch</h4>
                            </Card>
                        </a>

                        <a href='/office' className='card-link'>
                            <Card className='services-card'>
                                <Card.Img variant="top" src='/Images/office.jpg' />
                                <h4>Office Equipments and Computers</h4>
                            </Card>
                        </a>
                    </OwlCarousel>
                </div>
            </div>
        </div>
    )
}

export default Cctv