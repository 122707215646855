import React from 'react'
import './Porch.css'
import { Row, Col, Card } from 'react-bootstrap'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function Porch() {
    return (
        <div>
            <div className='porch-div'>
                <img src='/Images/Banner/porch-banner.jpg' alt='' className='service' />
                <h2>"Graceful Entrances, Secure Spaces"</h2>
            </div>

            <div className='object-div'>
                <h1>Tend & Car Porches</h1>
                <div className='description'>
                    <Row>
                        <Col xs={12} md={6} >
                            <img src='/Images/services/porch.jpg' alt='' />
                        </Col>
                        <Col xs={12} md={6}>
                            <p>The umbrella car parking shades come with maximum visual
                                comfort, these shades usually consist of a single supporting
                                structure such as a beam or a column that is fixed to the
                                ground, this results in minimum physical obstruction with
                                three ends open to park the cars easily and safely.
                                Ocean Waves Security System utilize only the premium
                                quality materials for these shades. These materials include
                                lightweight aluminum or heavy-duty steel for the shade
                                structure and varieties of fabrics to be draped over them
                                that include Polyvinyl chloride or PVC, High-density Polyeth-
                                ylene or HDPE, PVD coated fabrics, PTFE, Acrylic, Knitted and
                                Woven Canvases, and Tarpaulins. The use of these materials
                                makes the umbrella car parking shades stand in all kinds of
                                weather conditions. The water and heat resistant properties
                                make these shades fit for shelter during rain or from overex-
                                posure to the sun, regulating the internal temperature of
                                the car.</p>
                        </Col>
                    </Row>
                </div>
                <div className='types-of-service'>
                    <h1>Types of services</h1>

                    <OwlCarousel
                        autoplay
                        loop
                        items={4}
                        margin={2}
                        responsive={{
                            0: {
                                items: 1 // Number of items to show on small screens
                            },
                            768: {
                                items: 2 // Number of items to show on medium screens
                            },
                            1024: {
                                items: 4// Number of items to show on large screens
                            }
                        }}
                    >
                        <a href='/cctv' className='card-link'>
                            <Card className='services-card'>
                                <Card.Img variant="top" src='/Images/cctv-ser.jpg' />
                                <h4>CCTV Servivalence</h4>
                            </Card>
                        </a>

                        <a href='/network' className='card-link'>
                            <Card className='services-card'>
                                <Card.Img variant="top" src='/Images/networks.jpg' />
                                <h4>Network Survivalence</h4>
                            </Card>
                        </a>

                        <a href='/door' className='card-link'>
                            <Card className='services-card'>
                                <Card.Img variant="top" src='/Images/door-acess.jpg' />
                                <h4>Door Acess & Attendance System</h4>
                            </Card>
                        </a>

                        <a href='/gate' className='card-link'>
                            <Card className='services-card'>
                                <Card.Img variant="top" src='/Images/gate.jpg' />
                                <h4>Swing & Sliding gate</h4>
                            </Card>
                        </a>

                        <a href='/porch' className='card-link'>
                            <Card className='services-card'>
                                <Card.Img variant="top" src='/Images/car-proches.jpg' />
                                <h4>Tend & Car Porch</h4>
                            </Card>
                        </a>
                        <a href='/office' className='card-link'>
                            <Card className='services-card'>
                                <Card.Img variant="top" src='/Images/office.jpg' />
                                <h4>Office Equipments and Computers</h4>
                            </Card>
                        </a>

                    </OwlCarousel>
                </div>
            </div>
        </div>
    )
}

export default Porch