import React from 'react';
import './Banner.css'
import { Row, Col, Container, Card } from 'react-bootstrap';
import ProductList from '../LocalApi/ProductList';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function Banner() {


  return (
    <div className='main-div'>
      <OwlCarousel
        autoplay
        loop
        items={4}
        margin={2}
        responsive={{
          0: {
            items: 1 // Number of items to show on small screens
          },
          768: {
            items: 2 // Number of items to show on medium screens
          },
          1024: {
            items: 1// Number of items to show on large screens
          }
        }}
      >
        <div className='banner-div'>
          <img src='./Images/Banner/cctv-banner.jpg' alt='' className="d-block w-100" />
          <h2>Providing the best cctv<br /> security</h2>
        </div>
        <div className='network-div'>
          <img src='./Images/Banner/network-banner.jpg' alt='' className="d-block w-100" />
          <h2>Seamless Connectivity</h2>
        </div>
        <div className='gate-div'>
          <img src='./Images/Banner/gate-banner.jpg' alt='' className="d-block w-100" />
        </div>
        <div className='porch-div'>
          <img src='./Images/Banner/porch-banner.jpg' alt='' className="d-block w-100" />
          <h2>"Graceful Entrances, Secure Spaces"</h2>
        </div>
        <div className='door-div'>
          <img src='./Images/Banner/door-banner.jpg' alt='' className="d-block w-100" />
          <h2>Enhancing Security and Efficiency</h2>
        </div>

        <div className='office-div'>
          <img src='./Images/Banner/office-banner.jpg' alt='' className="d-block w-100" />
        </div>
      </OwlCarousel>

      <div className='experience'>

        <h4 ><span style={{ color: '#4e704d', fontWeight: "600" }}>Ocean Waves Security Systems</span> was established in the year <span style={{ color: '#4e704d', fontWeight: "600" }}>2006</span> in Abu Dhabi, the capital of United Arab Emirates.

          After all these years of successful business due to the continuous support from our valued customers, we are encouraged to make this Company Profile,which includes the complete details of the products and services we provide.</h4>

        <div>
          <Row>
            <Col xs={12} md={3}>
              <div className='group-div'>
                <img src='/Images/group.jpeg' alt='' className='icons' />
                <h2>25+</h2>
                <h5>Trained Experts</h5>
              </div>
            </Col>

            <Col xs={12} md={3}>
              <div className='group-div'>
                <img src='/Images/smiiles.png' alt='' className='icons' />
                <h2>5000+</h2>
                <h5>happy Customers</h5>
              </div>
            </Col>

            <Col xs={12} md={3}>
              <div className='group-div'>
                <img src='/Images/brand.png' alt='' className='icons' />
                <h2>50+</h2>
                <h5>Brands</h5>
              </div>
            </Col>

            <Col xs={12} md={3}>
              <div className='group-div'>
                <img src='/Images/product.png' alt='' className='icons' />
                <h2>8000+ </h2>
                <h5>Products</h5>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div className='work-div'>
        <h1>Our Services</h1>
        <OwlCarousel
          autoplay
          loop
          items={5}
          margin={2}
          responsive={{
            0: {
              items: 1 // Number of items to show on small screens
            },
            768: {
              items: 1 // Number of items to show on medium screens
            },
            1025: {
              items: 4// Number of items to show on large screens
            }
          }}
        >
          <a href='/cctv' className='card-link'>
            <Card className='services-card' >
              <Card.Img variant="top" src='/Images/cctv-ser.jpg' />
              <h4>CCTV Security System</h4>
              <p>CCTV CAMERAS Get the ultimate security for your business or home premises with  advanced CCTV and Access control system in Dubai, UAE. </p>
            </Card>
          </a>

          <a href='/network' className='card-link'>
            <Card className='services-card'>
              <Card.Img variant="top" src='/Images/networks.jpg' />
              <h4>Network surveillance</h4>
              <p>Network surveillance services ensure the optimal performance, security, and reliability of digital networks. </p>
            </Card>
          </a>

          <a href='/door' className='card-link'>
            <Card className='services-card'>
              <Card.Img variant="top" src='/Images/door-acess.jpg' />
              <h4>Door Acess & Attendance System</h4>
              <p>Door access and attendance register systems are integral components of modern security and workforce management.</p>
            </Card>
          </a>

          <a href='/gate' className='card-link'>
            <Card className='services-card'>
              <Card.Img variant="top" src='/Images/gate.jpg' />
              <h4>Swing & Sliding gate</h4>
              <p>SWING GATE Swing gate openers are designed for large-sized Swing gates, and such a system is highly reliable. </p>
            </Card>
          </a>

          <a href='/porch' className='card-link'>
            <Card className='services-card'>
              <Card.Img variant="top" src='/Images/car-proches.jpg' />
              <h4>Tend & Car Porch</h4>
              <p>OceanWaves offers top-notch Car Porch Services, specializing in cleaning, maintenance, and space optimization. </p>
            </Card>
          </a>

          <a href='/office' className='card-link'>
            <Card className='services-card'>
              <Card.Img variant="top" src='/Images/office.jpg' />
              <h4>Office Equipments and Computers</h4>
              <p>  The key to leveraging information to your advantage is purchasing and implementing the right computers and software for your business.</p>
            </Card>
          </a>

        </OwlCarousel>
      </div>

      <ProductList />

      <div className="brands-div">
        <h1>Our Brands</h1>
        <OwlCarousel
          autoplay
          loop
          items={5}
          margin={2}
          responsive={{
            0: {
              items: 1 // Number of items to show on small screens
            },
            768: {
              items: 1 // Number of items to show on medium screens
            },
            1025: {
              items: 4// Number of items to show on large screens
            }
          }}
        >
          <img src='./Images/Brands/brand-26.jpg' alt='' />
          <img src='./Images/Brands/brand-1.jpeg' alt='' />
          <img src='./Images/Brands/brand-2.jpeg' alt='' />
          <img src='./Images/Brands/brand-3.jpg' alt='' />
          <img src='./Images/Brands/brand-4.jpg' alt='' />
          <img src='./Images/Brands/brand-5.jpg' alt='' />
          <img src='./Images/Brands/brand-6.jpg' alt='' />
          <img src='./Images/Brands/brand-7.jpg' alt='' />
          <img src='./Images/Brands/brand-8.jpg' alt='' />
          <img src='./Images/Brands/brand-9.jpg' alt='' />
          <img src='./Images/Brands/brand-10.jpg' alt='' />
          <img src='./Images/Brands/brand-11.jpg' alt='' />
          <img src='./Images/Brands/brand-12.jpg' alt='' />
          <img src='./Images/Brands/brand-13.jpg' alt='' />
          <img src='./Images/Brands/brand-14.jpg' alt='' />
          <img src='./Images/Brands/brand-16.jpg' alt='' />
          <img src='./Images/Brands/brand-17.jpg' alt='' />
          <img src='./Images/Brands/brand-18.jpg' alt='' />
          <img src='./Images/Brands/brand-19.jpg' alt='' />
          <img src='./Images/Brands/brand-20.jpg' alt='' />
          <img src='./Images/Brands/brand-21.jpg' alt='' />


        </OwlCarousel>
      </div>

      <Container>
        <div className='contact-div'>
          <div className="product-heading">
            <h4>Contact-us</h4>
            <h6 className='contact-sub'>Use our contact form for all information request or <br />contact us directly using the contact information<br />below</h6>
            <div><Row>
              <Col xs={12} md={6}>
                <img src='/Images/location.jpeg' alt='' className='location-icon' />
                <p className='contact-data'>Near Lulu Xpress Hypermarket, Hamdan St. <br />
                  P.O Box: 107183, Abu Dhabi - U.A.E.</p>
              </Col>

              <Col xs={12} md={6}>
                <img src='/Images/phone.jpeg' alt='' className='location-icon' />
                <p className='contact-data'>0505805291 <br />
                  +971 2 67 67 921</p>
              </Col>
            </Row>
            </div>
          </div>
        </div>
      </Container>
    </div >
  );
}

export default Banner;